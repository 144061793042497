module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-577be95636/0/cache/gatsby-plugin-google-gtag-npm-5.14.0-7fc489a55d-930de28fb2.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-SE7LV5LTWD"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0,"send_page_view":true},"pluginConfig":{"head":true,"respectDNT":true,"delayOnRouteUpdate":0,"exclude":[],"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-889fef0844/0/cache/gatsby-plugin-catch-links-npm-5.14.0-3e7b33ed41-75112511c0.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-73c1e52387/0/cache/gatsby-remark-images-npm-7.14.0-a55a0fccfe-31b4418441.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-dce9d88ae6/0/cache/gatsby-plugin-manifest-npm-5.14.0-8a89b421cf-9663fdaca2.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bored Hacking","short_name":"boredhacking","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"static/images/profile-pic.jpg","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-3ee630a674/0/cache/gatsby-plugin-offline-npm-6.14.0-87805d241e-4804d282fe.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/profile-pic*"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-802abbf85b/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
